var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"small-12 columns content"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t('products'))+" "),_c('i',{staticClass:"fi-filter",on:{"click":function($event){_vm.showFilter = !_vm.showFilter}}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilter),expression:"showFilter"}]},[_c('ul',[_vm._l((_vm.columns),function(column){return _c('li',{key:column.name,class:{ inactive: !column.show },on:{"click":function($event){column.show = !column.show}}},[_c('i',{staticClass:"fi-eye"}),_vm._v(" "+_vm._s(_vm.$t(column.name))+" ")])}),_vm._l((_vm.groups),function(column){return _c('li',{key:column.name,class:{ inactive: !column.show },on:{"click":function($event){column.show = !column.show}}},[_c('i',{staticClass:"fi-eye"}),_vm._v(" "+_vm._s(_vm.$t(column.name))+" ")])})],2)]),_c('table',{attrs:{"cellpadding":"0","cellspacing":"0"}},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('products'))+" "+_vm._s(_vm.searchResultsCount))]),_vm._l((_vm.columns),function(column){return _c('th',{directives:[{name:"show",rawName:"v-show",value:(column.show),expression:"column.show"}],key:column.name,attrs:{"scope":"col","rowspan":column.rowspan}},[_vm._v(" "+_vm._s(_vm.$t(column.name))+" ")])}),_c('th',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.columns.find(function (column) { return column.name == 'avarage purchase price'; })
              .show
          ),expression:"\n            columns.find((column) => column.name == 'avarage purchase price')\n              .show\n          "}],attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t('amount'))+" ")]),_c('th',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.columns.find(function (column) { return column.name == 'last purchase price'; })
              .show
          ),expression:"\n            columns.find((column) => column.name == 'last purchase price')\n              .show\n          "}],attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t('amount'))+" ")]),_vm._l((_vm.groups),function(column){return _c('th',{directives:[{name:"show",rawName:"v-show",value:(column.show),expression:"column.show"}],key:column.name,attrs:{"scope":"col","rowspan":column.rowspan}},[_vm._v(" "+_vm._s(_vm.$t(column.name))+" ")])})],2),_c('tr',[_c('td',[_c('filter-input',{attrs:{"search":'products.name',"placeholder":"product"}})],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.columns.find(function (column) { return column.name == 'code'; }).show),expression:"columns.find((column) => column.name == 'code').show"}]},[_c('filter-input',{attrs:{"search":'products.code',"placeholder":"code"}})],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.columns.find(function (column) { return column.name == 'size'; }).show),expression:"columns.find((column) => column.name == 'size').show"}]},[_c('filter-input',{attrs:{"search":'products.size',"placeholder":"size"}})],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.columns.find(function (column) { return column.name == 'stock'; }).show),expression:"columns.find((column) => column.name == 'stock').show"}],staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("toNum")(_vm.stock))+" "+_vm._s(_vm.$t('pcs'))+" ")]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.columns.find(function (column) { return column.name == 'sells'; }).show),expression:"columns.find((column) => column.name == 'sells').show"}],staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("toNum")(_vm.sells))+" "+_vm._s(_vm.$t('pcs'))+" ")]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.columns.find(function (column) { return column.name == 'avarage purchase price'; })
              .show
          ),expression:"\n            columns.find((column) => column.name == 'avarage purchase price')\n              .show\n          "}],staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.products.reduce( function (sum, product) { return sum + (product.hidden ? 0 : parseInt(product.stock * product.avaragePurchasePrice)); }, 0 )))+" ")]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.columns.find(function (column) { return column.name == 'last purchase price'; })
              .show
          ),expression:"\n            columns.find((column) => column.name == 'last purchase price')\n              .show\n          "}],staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.products.reduce( function (sum, product) { return sum + (product.hidden ? 0 : parseInt(product.stock * product.lastPurchasePrice)); }, 0 )))+" ")]),_vm._l((_vm.groups),function(column){return _c('td',{directives:[{name:"show",rawName:"v-show",value:(column.show),expression:"column.show"}],key:column.name,staticClass:"text-center"},[_vm._v(" "+_vm._s(column.percentage)+" ")])})],2)]),_c("filtered-tbody",{tag:"tbody",attrs:{"products":_vm.products,"columns":_vm.columns,"groups":_vm.groups},on:{"setCount":function($event){return _vm.setCount($event)}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }